import { BsFillTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { FaGlobe } from "react-icons/fa";
import React from "react";


const MainContent = () => {
  return (
    <div className="main-content">
    <div>
      </div>
      <div className="welcome-message">
      <h1>Professional Mobile Car Detailer In San Diego</h1>
      <h2>Book Your Appointment Today And Get Your Vehicle Detailed From The Comfort Of Your Home</h2>
      </div>
      
      <div className="class-one">
        <h1>Packages</h1>
        <p>
          The prices displayed apply to compact vehicles and regular sized SUV's
          and trucks.
        </p>
        <p>
          There will be a minumun upcharge of $40 applied to any vehicles with
          pet hair, excessive sand, or heavily soiled vehicles.
        </p>
      </div>
      <div className="services">
        <div className="package">
          <h1>Wash & Wax $100</h1>
          <ul>
            <li>Exterior hand wash and dry</li>
            <li>Clay the exterior to remove contaminants</li>
            <li>Apply carnauba wax</li>
            <li>Wheels washed and dressed</li>
            <li>Interior Vaccumed</li>
          </ul>
        </div>
        <div className="package">
          <h1>$150 Mini Detail</h1>
          <ul>
            <li>Exterior hand wash and dry</li>
            <li>Apply spray wax</li>
            <li>Wheels washed and dressed</li>
            <li>Interior vacuumed</li>
            <li>Dashboard, cupholders, door panels, and other common areas cleaned and dressed</li>
            <li>Floor mats cleaned</li>
            <li>Glass and windows cleaned</li>
          </ul>
        </div>
        <div className="package">
          <h1>Clay, Wax & Seal $200</h1>
          <ul>
            <li>Exterior hand wash and dry</li>
            <li>Clay the exterior </li>
            <li>Apply carnauba wax and wax sealant</li>
            <li>Wheels washed and dressed</li>
            <li>Exterior trim dressed</li>
            <li>Interior thorough vacuuming</li>
            <li>Dashboard, cupholders, door panels, and other common areas cleaned and dressed</li>
            <li>Floor mats cleaned</li>
            <li>Glass and windows cleaned</li>
          </ul>
        </div>
        <div className="package">
        <h1>Complete Detail $250</h1>
        <ul>
          <li>Exterior hand wash and dry</li>
          <li>Clay the exterior</li>
          <li>Apply carnauba wax and wax sealant</li>
          <li>Wheels washed and dressed</li>
          <li>Interior thorough vacuuming</li>
          <li>
            Interior dashboard, door panels, and cupholder's cleaned
            and dressed
          </li>
          <li>Carpet and upholstery shampooed and degreased</li>
          <li>Leather surfaces cleaned and conditioned</li>
          <li>Floor mats cleaned</li>
          <li>Glass and windows cleaned</li>
        </ul>
      </div>
        <div className="package">
          <h1>Package Upgrades</h1>
          <ul>
            <li>Pet Hair Removal $50</li>
            <li>Engine Bay Detailed $50</li>
            <li>Clay Bar $50</li>
            <li>Interior Steam Cleaning $50</li>
            <li>Headlight Restoration $50</li>
            <li>Carpet & Upholstery Shampooed $80</li>
            <li>Leather Ceramic Coating $80</li>
          </ul>
        </div>
      </div>
      <div className="schedule">
        <h1>HOURS</h1>
        <p>All Services Are By Appointment Only</p>
        <div className="flex-row">
          <div className="text-start">
            <p>MONDAY:</p>
            <p>TUESDAY:</p>
            <p>WEDNESDAY:</p>
            <p>THURSDAY:</p>
            <p>FRIDAY:</p>
            <p>SATURDAY:</p>
            <p>SUNDAY:</p>
          </div>
          <div className="text-end">
            <p>CLOSED</p>
            <p>CLOSED</p>
            <p>CLOSED</p>
            <p>8:00 AM - 5:00 PM</p>
            <p>8:00 AM - 5:00 PM</p>
            <p>8:00 AM - 5:00 PM</p>
            <p>8:00 AM - 5:00 PM</p>
          </div>
        </div>
      </div>
      <div className="booking">
        <div className="booking-title">
          <h1>BOOK YOUR APPOINTMENT TODAY</h1>
          <h2>Please text me for a faster response. Thank You!</h2>
        </div>
        <div className="contact">
          {" "}
          <a href="tel:6195357389">
            <BsFillTelephoneFill className="icons" />
          </a>
          <a href="tel:6195357389"> (619) 535-7389 </a>
        </div>

        <div className="contact">
        {" "}
        <a href="https://book.squareup.com/appointments/0fz861yip139mu/location/L9278C45WS8D4/services">
          <FaGlobe className="icons" />
        </a>
        <a href="https://book.squareup.com/appointments/0fz861yip139mu/location/L9278C45WS8D4/services">
          Book Online{" "}
        </a>
      </div>

        <div className="contact">
          {" "}
          <a href="mailto:expresdetailsd@gmail.com">
            <MdEmail className="icons" />
          </a>
          <a href="mailto:expresdetailsd@gmail.com">
            expressdetailsd@gmail.com{" "}
          </a>
        </div>

      
      </div>
    </div>
  );
};
export default MainContent;
