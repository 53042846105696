import img from "./images/img2.png";
import logo from "./images/express (2).png";
import { GiHamburgerMenu } from "react-icons/gi";
import $ from "jquery";
import React from "react";

const Header = () => {
  const mobileNav = () => {
    console.log("Hellooooo");
    if ($(".mobile-nav").css("display") === "none") {
      $(".mobile-nav").css("display", "flex");
    } else if ($(".mobile-nav").css("display") === "flex") {
      $(".mobile-nav").css("display", "none");
    }
  };

  return (
    <div className="header">
      <img src={logo} alt={logo} />

      <GiHamburgerMenu onClick={mobileNav} className="nav-slide" />
      <ul className="nav">
        <li>
          <button
            className="navButton"
            onClick={() => {
              document
                .querySelector(".welcome-message")
                .scrollIntoView({ behavior: "smooth", block: "end" });
            }}
          >
            Home
          </button>
        </li>
        <li>
          <button
            className="navButton"
            onClick={() => {
              document
                .querySelector(".class-one")
                .scrollIntoView({ behavior: "smooth", block: "start" });
            }}
          >
            Services
          </button>
        </li>
        <li>
          <button
            className="navButton"
            onClick={() => {
              document
                .querySelector(".booking")
                .scrollIntoView({ behavior: "smooth", block: "start" });
            }}
          >
            Book Now
          </button>
        </li>
      </ul>
      <ul className="mobile-nav">
        <li>
          <button
            className="mobile-nav-button"
            onClick={() => {
              mobileNav();
              document
                .querySelector(".welcome-message")
                .scrollIntoView({ behavior: "smooth", block: "end" });
            }}
          >
            Home
          </button>
        </li>
        <li>
          <button
            className="mobile-nav-button"
            onClick={() => {
              mobileNav();
              document
                .querySelector(".class-one")
                .scrollIntoView({ behavior: "smooth", block: "start" });
            }}
          >
            Services
          </button>
        </li>
        <li>
          <button
            className="mobile-nav-button"
            onClick={() => {
              mobileNav();
              document
                .querySelector(".booking")
                .scrollIntoView({ behavior: "smooth", block: "start" });
            }}
          >
            Book Now
          </button>
        </li>
      </ul>
    </div>
  );
};

export default Header;
